import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar } from '@material-ui/core';
import NavigationDrawer from './navigation-drawer';
import HomeLink from './homelink';
import MasumiHayashiFoundationLogo from './MasumiHayashiFoundationLogo';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100vw',
  },
  appBar: {
    width: '100vw',
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color: 'white',
  },
  homeLink: {
    display: 'inline-block',
    padding: '0',
  },

  logo: {
    fill: 'white',
    marginTop: '1rem',
    transform: 'scale(.5)',
    transformOrigin: 'left center',

    [theme.breakpoints.down('sm')]: {
      transform: 'scale(.75)',
    },
    [theme.breakpoints.up('sm')]: {
      transform: 'scale(.75)',
    },
    [theme.breakpoints.up('md')]: {
      transform: 'scale(1)',
    },
    [theme.breakpoints.up('lg')]: {
      transform: 'scale(1.25)',
      transformOrigin: 'left top',
    },
    [theme.breakpoints.up('xl')]: {
      transform: 'scale(1.5)',
    },
  },

  fineArt: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    [theme.breakpoints.up('sm')]: {
      display: 'inline-flex',
    },
  },
  toolbar: {
    justifyContent: 'space-between',
  },
}));

export default function MaterialAppBar() {
  const classes = useStyles();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [accordionStates, setAccordionStates] = useState({});

  useEffect(() => {
    // Retrieve the drawer state from local storage
    const drawerState = localStorage.getItem('isDrawerOpen');
    if (drawerState !== null) {
      setIsDrawerOpen(JSON.parse(drawerState));
    }

    // console.log('isDrawerOpen read from localstorage: ', isDrawerOpen);
    // Retrieve the accordion states from local storage
    const accordionStates = localStorage.getItem('accordionStates');
    if (accordionStates !== null) {
      setAccordionStates(JSON.parse(accordionStates));
    }
  }, []);

  useEffect(() => {
    // Store the drawer state in local storage
    localStorage.setItem('isDrawerOpen', JSON.stringify(isDrawerOpen));
    // console.log('store isDrawerOpen in localstorage: ', isDrawerOpen);
  }, [isDrawerOpen]);

  useEffect(() => {
    // Store the accordion states in local storage
    localStorage.setItem('accordionStates', JSON.stringify(accordionStates));
    // console.log('store accordionStates in localstorage: ', accordionStates);
  }, [accordionStates]);

  const handleDrawerOpen = () => {
    setIsDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setAccordionStates({
      panel1: false,
      panel2: false,
      panel3: false,
      panel4: false,
      panel5: false,
      panel6: false,
      panel7: false,
      panel8: false,
      panel9: false,
      panel10: false,
      panel11: false,
      panel12: false,
      panel13: false,
      panel14: false,
      panel15: false,
      [panel]: isExpanded,
    });
  };

  return (
    <div className={classes.root}>
      <AppBar className={classes.appBar} position="relative">
        <Toolbar className={classes.toolbar}>
          <HomeLink
            to="/"
            activeClassName="current-page"
            className="classes.homeLink"
            fontWeight="bold"
            title="Use the tab, shift, and return keys to quickly navigate the menus and select images."
          >
            <MasumiHayashiFoundationLogo
              className={classes.logo}
              data-testid="logo"
            />
          </HomeLink>
          <NavigationDrawer
            className={classes.menu}
            isDrawerOpen={isDrawerOpen}
            handleDrawerClose={handleDrawerClose}
            handleDrawerOpen={handleDrawerOpen}
            accordionStates={accordionStates}
            handleAccordionChange={handleAccordionChange}
          />
        </Toolbar>
      </AppBar>
    </div>
  );
}

import React from 'react';
import { Global, css } from '@emotion/react';
import Helmet from 'react-helmet';
import useSiteMetadata from '../hooks/use-sitemetadata';
import MaterialAppBar from './material-app-bar.js';
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const MaterialUILayout = ({ children }) => {
  const { title, description } = useSiteMetadata();

  let theme = createTheme({
    palette: {
      primary: {
        main: '#FFD',
      },
      secondary: {
        main: '#ddf',
      },
      tertiary: {
        main: '#fc3',
      },
      background: {
        paper: 'transparent',
      },
      text: {
        primary: '#eee',
        secondary: '#aaa',
      },
    },
    typography: {
      h1: {
        fontSize: '2rem',
        marginBottom: '1rem',
      },

      h2: {
        fontSize: '1.3rem',
      },
      h3: {
        fontSize: '1.2rem',
      },
      h4: {
        fontSize: '1.0rem',
      },
      h5: {
        fontSize: '1rem',
      },
      button: {
        fontSize: '1rem',
        textTransform: 'none',
      },
      body1: {
        fontSize: '1rem',
        maxWidth: '60rem',
        marginBottom: '1rem',
      },
      body2: {
        fontSize: '1rem',
        maxWidth: '60rem',
      },
    },
  });
  // theme = responsiveFontSizes(theme);

  theme.typography.h1 = {
    margin: '1rem auto',

    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
      maxWidth: '22rem',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.1rem',
      maxWidth: '30rem',
      lineHeight: '2rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1.5rem',
      maxWidth: '40rem',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '2rem',
      maxWidth: '60rem',
      margin: '3rem auto',
    },
  };

  theme.typography.h2 = {
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
      maxWidth: '22rem',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '1rem',
      maxWidth: '22rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1.1rem',
      maxWidth: '22rem',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.3rem',
      maxWidth: '22rem',
      margin: '1rem auto',
    },
  };

  theme.typography.h3 = {
    [theme.breakpoints.down('sm')]: {
      fontSize: '.8rem',
      maxWidth: '22rem',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '1rem',
      maxWidth: '30rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1.1rem',
      maxWidth: '40rem',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.3rem',
      maxWidth: '60rem',
      margin: '1rem auto',
    },
  };
  theme.typography.body2 = {
    color: 'gold',
    margin: '1rem auto',

    [theme.breakpoints.down('sm')]: {
      fontSize: '.75rem',
      maxWidth: '20rem',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '.75rem',
      maxWidth: '30rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '.8rem',
      maxWidth: '40rem',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '1rem',
      maxWidth: '60rem',
    },
  };
  theme.typography.body1 = {
    marginBottom: '1rem',
    margin: '1rem auto',

    [theme.breakpoints.down('sm')]: {
      fontSize: '.75rem',
      maxWidth: '20rem',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '.8rem',
      maxWidth: '30rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1rem',
      maxWidth: '40rem',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.2rem',
      maxWidth: '60rem',
    },
  };
  return (
    <div
      className="container"
      css={css`
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        height: 100vh;
        width: 100vw;
      `}
    >
      <Global
        styles={css`
        * {
          box-sizing: border-box;
          margin: 0;
          padding: 0;
        }
        /* * + * {
          margin-top: 1rem;
        } */

        html,
        body {
          background: #000;
          height: 100%;
          margin: 0;
          color: #bbb;
          font-family: Roboto, "Segoe UI", -apple-system, Helvetica, Arial,
            sans-serif;
          font-size: 18px;
          line-height: 1.4;
          display:flex;
          overflow-x: hidden;
          overflow-y: auto; 
          /* remove margin for the main div that Gatsby mounts into */
          > div {
            margin-top: 0;
          }
         
         
          h1,
          h2,
          h3,
          h4,
          h5,
          h6 {
            color: #eee;
            line-height: 1.3rem;

            + * {
              margin-top: 0.5rem;
            }
          }
          h1 {
            line-height: 1.8rem;
          }

          strong {
            color: #eee;
          }

          li {
            margin-top: 0.25rem;
            margin-left: .5rem;   
          }
        }

        footer,
        header {
          width: 100vw;
          /* background: #222; */
           '& > h1' {
            margin: 0;
          }
        }
        main {
          flex-grow: 1;
          width: 100vw;

        }

        footer{
          flex-shrink: 0;
      
         },
      `}
      />
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      <ThemeProvider theme={theme}>
        <MaterialAppBar />

        <main>
          <div className="content">{children}</div>
        </main>

        <footer
          className="footer"
          css={css`
            display: flex;
            justify-content: center;

            align-items: center;
          `}
        >
          <Typography variant="body2" color="textPrimary">
            © 2023, Masumi Hayashi Foundation
          </Typography>
        </footer>
      </ThemeProvider>
    </div>
  );
};

export default MaterialUILayout;

import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AccordionNavList from './AccordionNavList';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  list: {
    width: 'auto',
    maxWidth: '50vw',
    color: theme.palette.grey[200],
    background: '#333',
    padding: '10px',
  },
  fullList: {
    width: 'auto',
  },
  galleriesLabel: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    [theme.breakpoints.up('sm')]: {
      display: 'inline-flex',
    },
  },
  galleriesBtn: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    [theme.breakpoints.up('sm')]: {
      display: 'inline-flex',
    },
  },
}));

export default function NavigationDrawer({
  isDrawerOpen,
  handleDrawerOpen,
  handleDrawerClose,
  accordionStates,
  handleAccordionChange,
}) {
  const classes = useStyles();

  const toggleDrawer = () => {
    if (isDrawerOpen) {
      handleDrawerClose();
    } else {
      handleDrawerOpen();
    }
  };

  const sideList = (side) => (
    <div className={classes.list} role="presentation">
      <AccordionNavList
        handleAccordionChange={handleAccordionChange}
        accordionStates={accordionStates}
        data-testid="nav-list"
      />
    </div>
  );

  return (
    <div>
      <Button className={classes.galleriesBtn} onClick={toggleDrawer}>
        <Typography
          variant="button"
          className={classes.galleriesLabel}
          color="secondary"
          data-testid="galleries-button"
        >
          Galleries
        </Typography>
      </Button>
      <IconButton
        edge="start"
        className={classes.menuButton}
        color="secondary"
        aria-label="menu"
        onClick={toggleDrawer}
        title="Use the tab, shift, and return keys to quickly navigate the menus and select images."
        data-testid="menu-button"
      >
        <MenuIcon />
      </IconButton>
      <Drawer
        className={classes.drawer}
        anchor="right"
        open={isDrawerOpen}
        onClose={handleDrawerClose}
        transitionDuration={0}
      >
        {sideList('right')}
      </Drawer>
    </div>
  );
}

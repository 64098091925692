import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';

const NavLink = styled(Link)`
  color: #bbf;
  font-weight: normal;
  line-height: 1;
  padding 0.25rem;
  text-decoration: none;
  background-image: none;

  &:hover {
    text-decoration: underline;
  }
  &.current-page {
    border-bottom: 2px solid #222;
  }

  &:last-of-type {
    margin-right:0;
  }

`;

export default NavLink;
